import { TitleCasePipe } from '@angular/common';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl, CdkTreeModule } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { Folder, FolderType } from '@designage/gql';
import { IFolderNode } from '@desquare/interfaces';
import { FolderService, MediaService } from '@desquare/services';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  CdkDragDrop,
  CdkDragEnter,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { dragDropCursorUtil } from '@desquare/utils';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MediaStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    DragDropModule,
    CdkTreeModule,
    NgbTooltipModule,
    TranslatePipe,

    MatMenuModule,
    MatButtonModule,
    TitleCasePipe,
  ],
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  //providers: [FolderService], // note this will be a new instance of folder service for every <app-folder-tree>
})
export class FolderTreeComponent {
  mediaStore = inject(MediaStore);

  folderService = inject(FolderService);
  mainFolderTreeDataSource = computed(
    () => new ArrayDataSource<IFolderNode>(this.filteredFolderTree()),
  );
  mainFolderTreeControl = new NestedTreeControl<IFolderNode>(
    (node) => node.children,
  );

  trashFolderTreeDataSource = computed(
    () => new ArrayDataSource<IFolderNode>(this.trashFolderTree()),
  );
  trashFolderTreeControl = new NestedTreeControl<IFolderNode>(
    (node) => node.children,
  );

  // @ViewChild('tree') tree!: CdkTree;

  folders = this.mediaStore.folderTree;
  filteredFolderTree = computed(() => {
    let folderTree;
    if (this.removeFolderIds.length > 0) {
      // get only the folders not in the remove folder list
      // this will cause the tree generator algorithm to not include the folders
      // including its descendants (since orphaned nodes are ignored)
      folderTree = this.createMainFolderTree(
        this.folders().filter(({ id }) => !this.removeFolderIds.includes(id)),
      );
    } else {
      // if selectedFolderId === null then create the normal main folder tree
      folderTree = this.createMainFolderTree(this.folders());
    }
    // manually set the dataNodes
    this.mainFolderTreeControl.dataNodes = folderTree;

    // expand root node initially
    // const rootNode = this.mainFolderTreeControl.dataNodes.find(
    //   ({ id }) => id === this.rootNodeId,
    // );

    // if (rootNode) this.mainFolderTreeControl.expand(rootNode);
    this.mainFolderTreeControl.expandAll();
    return folderTree;
  });
  trashFolderTree = computed(() => this.createTrashFolderTree(this.folders()));

  profileId?: string;

  showNodeMenu = input(true);
  @Input() showTrashFolderTree = true;
  @Input() removeFolderIds: string[] = [];

  @Input() selectedFolderId: string | null = null;
  @Output() selectFolderId = new EventEmitter<string | null>();

  rootNodeId = null;
  isRootNode = (_: number, node: IFolderNode) => node.id === this.rootNodeId;
  isTrashNode = (_: number, node: IFolderNode) =>
    node.folderType === FolderType.Trash;
  getTotalItemsInFolder = ({ children, totalMediaCount }: IFolderNode) =>
    children.length + (totalMediaCount ?? 0);

  disableEmptyTrashFolderButton = false;
  foldersIds$!: Observable<(string | null)[]>;

  // this is an optional optimization for the tree,
  // currently causes issues with refetching the data
  // folderTrackBy = (index: number, node: IFolderNode) => node.id;

  constructor(
    private translateService: TranslateService,
    private mediaService: MediaService,
  ) {
    effect(() => {
      // console.log('this.folders()', this.folders());
      // console.log('mediaInFolder', this.mediaStore.filteredMedia().length);
    });
    effect(() => {
      const folders = this.folders();
      untracked(() => {
        this.createIdList(folders);
      });
    });
  }

  addFolder(parentFolderId?: string) {
    this.folderService.openCreateFolderDialog(parentFolderId);
  }

  editFolder(folderId: string) {
    const folder = this.folders().find(({ id }) => id === folderId);

    if (!folder) return console.error('editFolder: no folder found');

    this.folderService.openUpdateFolderDialog(folderId, {
      name: folder.name,
      isPublic: folder.isPublic,
    });
  }

  deleteFolder(folderId: string, folderName: string) {
    this.folderService.openDeleteFolderDialog([
      {
        id: folderId,
        name: folderName,
      },
    ]);
  }

  permanentDeleteFolder(folderId: string, folderName: string) {
    this.folderService.openPermanentDeleteFolderDialog([
      {
        id: folderId,
        name: folderName,
      },
    ]);
  }

  emptyTrashFolder(trashNode: IFolderNode) {
    if (!trashNode.id) return;

    this.folderService.openPermanentDeleteFolderDialog([
      { id: trashNode.id, name: this.translateService.instant('TRASH_BIN') },
    ]);

    // const trashFolderChildren: Pick<Folder, 'id' | 'name'>[] =
    //   trashNode.children.map(({ id, name }) => {
    //     // note: we are sure that id cannot be null while being a child folder
    //     // since only the root node can have a id === null, hence the "id!"
    //     return { id: id!, name };
    //   });
    // this.folderService.openPermanentDeleteFolderDialog(trashFolderChildren);
  }

  moveFolder(folderId: string, folderName: string) {
    this.folderService.openMoveFolderToFolderDialog([
      { id: folderId, name: folderName },
    ]);
  }

  selectFolder(folderId: string | null) {
    this.selectedFolderId = folderId;
    // old toggle logic
    // this.selectedFolderId =
    //   folderId === this.selectedFolderId ? null : folderId;

    this.selectFolderId.emit(this.selectedFolderId);
  }

  createIdList(folders: Folder[]) {
    // Create an array of folder ids to be used for the media list drag and drop
    let currentFolderId: string;
    this.selectedFolderId === null
      ? (currentFolderId = 'nodeTreeRoot')
      : (currentFolderId = this.selectedFolderId);

    const idList = [
      'nodeTreeRoot',
      ...(folders.map(({ id }) => id) as string[]),
    ];
    idList.forEach((id, index) => {
      if (id === currentFolderId) idList.splice(index, 1);
    });
    this.mediaService.setFolderIds([...idList]);
  }

  createMainFolderTree(folders: Folder[]): IFolderNode[] {
    // filter in root folders
    // filter folders by folder type then create the folder node tree
    // if folder.folderType = undefined or null, then it is a basic folder

    const mainFolderTree = this.folderService.toFolderNodeTree(folders, {
      prefilter: ({ folderType }) =>
        folderType === undefined || folderType === null,
    });

    // root node
    // note(s):
    //  - the root node is just a frontend concept it doesn't actually exist in the backend
    //  - all the folder data will be pushed into the children field of root node
    //  - root node should not be editable
    const rootNode: IFolderNode = {
      id: this.rootNodeId,
      name: this.translateService.instant('ROOT_FOLDER'),
      children: mainFolderTree,
      // totalMediaCount: this.mediaStore.getFolderMediaCount(this.rootNodeId),
    };

    return [rootNode];
  }

  createTrashFolderTree(folders: Folder[]): IFolderNode[] {
    // filter in trash folder
    // post filtering the tree results in getting a branch of the tree
    // in this case we get the whole trash tree branch

    const trashFolderTree = this.folderService
      .toFolderNodeTree(folders, undefined, this.mediaStore.media())
      .filter(({ folderType }) => folderType === FolderType.Trash);

    // if trashFolderTree is empty then create proxy trash folder node
    if (trashFolderTree.length === 0) {
      const proxyTrashFolderNode: IFolderNode = {
        id: 'TRASH', // note: this is just a proxy id
        name: this.folderService.TRASH_FOLDER_NAME,
        children: [],
        folderType: FolderType.Trash,
      };
      return [proxyTrashFolderNode];
    }

    return trashFolderTree;
  }

  async drop(event: CdkDragDrop<IFolderNode>) {
    console.log('drop', event);

    // if target folder is root then set targetFolderId to null
    const targetFolderId =
      event.container.id === 'nodeTreeRoot' ? null : event.container.id;

    // move media to target folder
    lastValueFrom(
      this.mediaService.moveMediaToFolder({
        folderId: targetFolderId,
        mediaIds: [event.item.data.id],
      }),
    );

    // reset drag and drop cursor
    dragDropCursorUtil.cursorReset();
  }

  dragEnter(event: CdkDragEnter) {
    // console.log('dragEntered', event);
    this.mainFolderTreeControl.expandAll();
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreateFolderInput } from '@designage/gql';
import { CurrentUserService } from '@desquare/services';
import { TranslatePipe } from '@ngx-translate/core';
import { FormDialogComponent } from './form-dialog.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslatePipe,

    FormDialogComponent,
  ],
  selector: 'app-create-folder-dialog',
  template: `<app-form-dialog
    headerText="CREATE_FOLDER"
    [valid]="isFormValid"
    [pristine]="isFormPristine"
    [values]="createFolderInput"
    dismissButtonText="CANCEL"
    closeButtonText="SAVE"
  >
    <form [formGroup]="folderForm">
      <div class="mb-3">
        <label class="form-label" for="name">{{ 'NAME' | translate }}*</label>
        <input
          type="text"
          class="form-control form-control-dark"
          formControlName="name"
        />
      </div>
      <div
        class="alert alert-danger"
        *ngIf="
          folderForm &&
          !folderForm.controls['name'].pristine &&
          folderForm.controls['name'].hasError('required')
        "
      >
        {{ 'NAME_IS_REQUIRED' | translate }}
      </div>
    </form>
  </app-form-dialog> `,
})
export class CreateFolderDialogComponent implements OnInit {
  // createFolderInput?: CreateFolderInput;
  loading = false;
  loaderMessage?: string;

  @Input() parentFolderId?: string;

  get profileId() {
    return this.currentUserService.currentProfile?.id;
  }

  // form variables
  folderForm!: FormGroup;
  get isFormValid() {
    return this.folderForm.valid;
  }
  get isFormPristine() {
    return this.folderForm.pristine;
  }
  get createFolderInput(): CreateFolderInput {
    return this.folderForm.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    // TODO: refactor later, remove unnecessary fields, only name is used
    this.folderForm = this.formBuilder.group({
      profileId: [this.profileId || null, Validators.required],
      name: [null, Validators.required],
      isPublic: true,
      parentFolderId: this.parentFolderId,
    });
  }
}
